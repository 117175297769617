export const PubliclyAvailableLLMModels = ["gpt-3.5-turbo"] as const;
export const RestrictedLLMModels = [
  "gpt-4",
  "gemini-1.5-flash",
  "gemini-1.5-pro",
  "claude-3-haiku",
  "claude-3-sonnet",
  "claude-3-5-sonnet",
  "gpt-3.5-turbo-test",
  "gpt-4o",
  "gpt-4o-test",
  "formx-self-host-llm",
] as const;
export const LLMModelsWithVision = [
  "claude-3-haiku",
  "claude-3-sonnet",
  "claude-3-5-sonnet",
  "gpt-4o",
  "gpt-4o-test",
  "gemini-1.5-flash",
  "gemini-1.5-pro",
];
export const AllLLMModels = [
  ...PubliclyAvailableLLMModels,
  ...RestrictedLLMModels,
] as const;
