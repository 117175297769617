import {
  ChoiceGroup,
  CommandButton,
  IButton,
  IContextualMenuProps,
  IconButton,
  SearchBox,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cn from "classnames";
import React, {
  KeyboardEvent,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

import { SUPPORTED_EXTRACT_MIME } from "../../constants";
import { useLocale } from "../../contexts/locale";
import { FilePrimaryButton } from "../FileButton";
import { ActionButton, DefaultButton } from "../WrappedMSComponents/Buttons";
import styles from "./styles.module.scss";

export type FilterResultOptionKey = "all" | "failed" | "extracted";

interface WorkspaceDocumentTopBarProps {
  className?: string;
  fileNameSearchText: string;
  onFileNameSearch: (searchText: string) => void;
  isOutOfQuota: boolean;
  onSelectFiles: (files: File[]) => void;
  showDeleteButtons: boolean;
  selectedCount: number;
  onDeleteAll: () => void;
  onDeleteSelected: () => void;
  onClickExport: () => void;
  isPreparingExport: boolean;
  hasExtractingDocuments: boolean;
  hasDocuments: boolean;
  onSelectFilterResult: (key: FilterResultOptionKey) => void;
  selectedFilterOptionKey?: FilterResultOptionKey;
  isReExtractAllFailedExtractionsEnabled?: boolean;
  onReExtractAllFailedExtractions: () => void;
}

const WorkspaceDocumentTopBar = (
  props: WorkspaceDocumentTopBarProps
): ReactElement => {
  const {
    className,
    isOutOfQuota,
    onSelectFiles,
    fileNameSearchText,
    onFileNameSearch,
    showDeleteButtons,
    selectedCount,
    onDeleteAll,
    onDeleteSelected,
    onClickExport,
    isPreparingExport,
    hasExtractingDocuments,
    hasDocuments,
    onSelectFilterResult,
    selectedFilterOptionKey,
    isReExtractAllFailedExtractionsEnabled = false,
    onReExtractAllFailedExtractions,
  } = props;

  const { localized } = useLocale();

  const [searchValue, setSearchValue] = useState(fileNameSearchText);

  useEffect(() => {
    setSearchValue(fileNameSearchText);
  }, [fileNameSearchText]);

  const _onSearchValueChange = (
    ev?: React.ChangeEvent<HTMLInputElement>,
    newValue?: string
  ) => {
    ev?.preventDefault();
    ev?.stopPropagation();
    setSearchValue(newValue ?? "");
  };

  const onFiles = useCallback(
    (files?: File[]) => onSelectFiles(files ?? []),
    [onSelectFiles]
  );

  const onKeyUp = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        onFileNameSearch(searchValue);
      }
    },
    [onFileNameSearch, searchValue]
  );

  const onClear = useCallback(() => {
    setSearchValue("");
    onFileNameSearch("");
  }, [onFileNameSearch]);

  const _onClickExport = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault();
    ev.stopPropagation();
    onClickExport();
  };
  const filterResultButtonRef = useRef<IButton>(null);

  const filterResultProps = React.useMemo<IContextualMenuProps>(() => {
    return {
      onRenderMenuList: menu => {
        if (!menu) {
          return null;
        }
        return (
          <ChoiceGroup
            className="px-3 pb-3"
            selectedKey={selectedFilterOptionKey}
            onChange={(ev, option) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              filterResultButtonRef.current?.dismissMenu();
              if (option) {
                onSelectFilterResult(option.key as FilterResultOptionKey);
              }
            }}
            options={menu.items.map(item => ({
              key: item.key,
              text: item.text ?? "",
              onRenderField: (item, defaultRender) => {
                if (!defaultRender || !item) {
                  return null;
                }
                return (
                  <div className="flex flex-row">
                    {defaultRender(item)}
                    {item.key === "failed" && (
                      <ActionButton
                        className="h-[20px] ml-[43px] text-primary-200 active:text-primary-300 disabled:text-[#7f8c8d]"
                        textId="workspace.document.top_bar.filter_result_option.failed.button.retry_all"
                        disabled={!isReExtractAllFailedExtractionsEnabled}
                        onClick={onReExtractAllFailedExtractions}
                      />
                    )}
                  </div>
                );
              },
            }))}
          />
        );
      },
      isBeakVisible: false,
      items: [
        {
          key: "all",
          text: localized(
            "workspace.document.top_bar.filter_result_option.all"
          ),
          canCheck: true,
          checked: selectedFilterOptionKey === "all",
        },
        {
          key: "failed",
          text: localized(
            "workspace.document.top_bar.filter_result_option.failed"
          ),
          canCheck: true,
          checked: selectedFilterOptionKey === "failed",
        },
        {
          key: "extracted",
          text: localized(
            "workspace.document.top_bar.filter_result_option.extracted"
          ),
          canCheck: true,
          checked: selectedFilterOptionKey === "extracted",
        },
      ],
      onItemClick(_ev?, item?) {
        if (item) {
          onSelectFilterResult(item.key as FilterResultOptionKey);
        }
      },
    };
  }, [
    localized,
    onSelectFilterResult,
    selectedFilterOptionKey,
    onReExtractAllFailedExtractions,
    isReExtractAllFailedExtractionsEnabled,
  ]);

  return (
    <div className={cn(styles.topBar, className)}>
      <SearchBox
        className={styles.searchBox}
        placeholder={localized("workspace.document.top_bar.search_box.label")}
        value={searchValue}
        onChange={_onSearchValueChange}
        onKeyUp={onKeyUp}
        onClear={onClear}
      />
      <div className={styles.rightGroup}>
        <CommandButton
          componentRef={filterResultButtonRef}
          text={localized("workspace.document.top_bar.button.filter_result", {
            selectedOption:
              filterResultProps.items.find(
                o => o.key === selectedFilterOptionKey
              )?.text ?? "",
          })}
          iconProps={{
            iconName: "Filter",
          }}
          styles={{
            root: {
              color: "#201F1E",
              fontWeight: 600,
              height: 32,
            },
            rootHovered: {
              color: "#201F1E",
              backgroundColor: "#F3F2F1",
            },
            rootExpanded: {
              color: "#201F1E",
              backgroundColor: "#F3F2F1",
            },
            icon: {
              color: "#201F1E",
            },
            iconHovered: {
              color: "#201F1E",
            },
            iconPressed: {
              color: "#201F1E",
            },
          }}
          menuProps={filterResultProps}
          onRenderMenuIcon={() => <div />}
        />
        {isOutOfQuota ? (
          <p className={styles.outOfQuota}>
            <FormattedMessage id="workspace.document.top_bar.out_of_quota" />
          </p>
        ) : null}
        <FilePrimaryButton
          textId="workspace.document.top_bar.upload_button.label"
          onFiles={onFiles}
          accept={SUPPORTED_EXTRACT_MIME.join(",")}
          isMultiple={true}
          disabled={isOutOfQuota}
        />
        <DefaultButton
          loading={isPreparingExport}
          textId={
            selectedCount <= 0
              ? "workspace.document.top_bar.export_button.label.all"
              : "workspace.document.top_bar.export_button.label"
          }
          textValues={{ count: selectedCount }}
          onClick={_onClickExport}
          disabled={
            isPreparingExport || hasExtractingDocuments || !hasDocuments
          }
        />
        <IconButton
          styles={{
            root: {
              color: "#201F1E",
              border: "1px solid #8A8886",
            },
            rootHovered: {
              color: "#201F1E",
            },
            rootPressed: {
              color: "#201F1E",
            },
            rootExpanded: {
              color: "#201F1E",
            },
          }}
          menuIconProps={{ iconName: "MoreVertical" }}
          disabled={!showDeleteButtons}
          menuProps={{
            items: !showDeleteButtons
              ? []
              : [
                  ...(selectedCount > 0
                    ? [
                        {
                          key: "deleteSelected",
                          name: localized(
                            "workspace.document.top_bar.delete_selected",
                            {
                              count: selectedCount,
                            }
                          ),
                          onClick: onDeleteSelected,
                        },
                      ]
                    : []),
                  {
                    key: "deleteAll",
                    name: localized("workspace.document.top_bar.delete_all"),
                    onClick: onDeleteAll,
                  },
                ],
          }}
        />
      </div>
    </div>
  );
};

export default WorkspaceDocumentTopBar;
