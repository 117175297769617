import {
  ChoiceGroup,
  Dialog,
  DialogType,
  IChoiceGroupOption,
  IDialogContentProps,
  IModalProps,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { default as classnames, default as cn } from "classnames";
import * as React from "react";
import { useNavigate } from "react-router";
import { Location, unstable_useBlocker as useBlocker } from "react-router-dom";

import { useConfirmModalActionCreator } from "../../actions/confirmModal";
import { ButtonId } from "../../constants/buttonids";
import { EXTRACTED_CONTENT_SCHEMA_TEMPLATE } from "../../constants/extractedContentSchemaTemplate";
import { useFSLCustomModelEditor } from "../../contexts/fslCustomModelEditor";
import { useLocale } from "../../contexts/locale";
import arrowBullet from "../../images/fsl/arrow-bullet.svg";
import { ConfirmModalType } from "../../types/confirmation";
import {
  ExtractedContentSchema,
  ExtractedContentSchemaAccessor,
  ExtractedContentSchemaField,
  ExtractedContentSchemaType,
} from "../../types/extractedContentSchema";
import {
  ExtractedContentSchemaValidationResult,
  ExtractedContentSchemaValidationResultAccessor,
} from "../../validators/extractedContentSchemaValidator";
import { DangerButton } from "../DangerButton";
import { FieldItemEditPanel } from "../FSLFieldItemEditPanel";
import { FSLFieldSchemaList } from "../FSLFieldSchemaList";
import { Img } from "../Img";
import { PageHeader } from "../PageHeader";
import { SmallIconButton } from "../SmallIconButton";
import {
  ActionButton,
  DefaultButton,
  PrimaryButton,
} from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";
import { ManageFieldsGuidance } from "./ManageFieldsGuidance";
import styles from "./styles.module.scss";

interface FSLFieldItemProps {
  name: string;
  type: string;
  hasError?: boolean;
  isSelected?: boolean;
  isList: boolean;
  index: number;
  subFields: ExtractedContentSchemaField[];
  onItemClicked?: () => void;
  onTrashClicked?: () => void;
}

type ArrowBulletProps = {
  isLast: boolean;
};

export function ArrowBullet(props: ArrowBulletProps) {
  const { isLast } = props;

  const classes = cn(
    styles["arrow-bullet"],
    isLast ? styles["arrow-bullet-is-last"] : styles["arrow-bullet-not-last"]
  );

  return (
    <div className={classes}>
      <Img src={arrowBullet} />
    </div>
  );
}

export function FSLFieldItem(props: FSLFieldItemProps) {
  const { name, type, isSelected, index, isList, subFields } = props;
  const { setSelectedEditingSchemaFieldIndex, removeEditingSchemaField } =
    useFSLCustomModelEditor();

  const hasError = props.hasError ?? false;

  const baseClasses = React.useMemo(() => {
    const classes = [styles["field-item"]];
    if (isSelected) {
      classes.push(styles["field-item-selected"]);
    }

    if (hasError) {
      classes.push(styles["field-item-error"]);
    }

    return classes.join(" ");
  }, [isSelected, hasError]);

  const onClicked = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedEditingSchemaFieldIndex(index);
    },
    [setSelectedEditingSchemaFieldIndex, index]
  );

  const onTrashClicked = React.useCallback(() => {
    removeEditingSchemaField(index);
  }, [removeEditingSchemaField, index]);

  return (
    <div>
      <div className={baseClasses} onClick={onClicked}>
        <div className={styles["field-item-left"]}>
          <div className={styles["field-item-name"]}>{name}</div>
          <div className={styles["field-item-type"]}>
            <FormattedMessage id={`fsl_custom_model.field_type.${type}`} />
            {isList && type !== ExtractedContentSchemaType.FieldGroup && (
              <FormattedMessage id={`fsl_custom_model.field_type.is_list`} />
            )}
          </div>
        </div>
        <div className={styles["field-item-right"]}>
          <div className={styles["trash-icon"]}>
            <SmallIconButton iconName="IconTrash" onClick={onTrashClicked} />
          </div>
        </div>
      </div>
      {type === ExtractedContentSchemaType.FieldGroup && (
        <>
          {subFields.length === 0 ? (
            <div className={styles["field-item-no-sub-field"]}>
              <ArrowBullet isLast={true} />
              <FormattedMessage id="fsl_custom_model.field_schema_editor.item_field.no_sub_field" />
            </div>
          ) : (
            subFields.map((field, index) => {
              return (
                <div className={styles["field-item-sub-field"]} key={index}>
                  <div className={styles["field-item-sub-field-title"]}>
                    <ArrowBullet isLast={index === subFields.length - 1} />
                    {field.name}
                  </div>
                  <div className={styles["field-item-sub-field-type"]}>
                    {field.type}
                  </div>
                </div>
              );
            })
          )}
        </>
      )}
    </div>
  );
}

interface DocumentTypePanelProps {
  editingExtractedContentSchemaValidationResult: ExtractedContentSchemaValidationResult;
}

function DocumentTypePanel(props: DocumentTypePanelProps) {
  const { localized } = useLocale();
  const { setEditingDocumentType, editingExtractedContentSchema } =
    useFSLCustomModelEditor();
  const { editingExtractedContentSchemaValidationResult } = props;

  const onChange = React.useCallback(
    (
      event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
      value?: string
    ) => {
      event.preventDefault();
      event.stopPropagation();
      setEditingDocumentType(value ?? "");
    },
    [setEditingDocumentType]
  );

  const nameFieldError = React.useMemo(() => {
    if (editingExtractedContentSchemaValidationResult.emptyName) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.field_required_error"
      );
    }

    if (editingExtractedContentSchemaValidationResult.invalidFormatName) {
      return localized(
        "fsl_custom_model.field_schema_editor.error.invalid_field_name_error"
      );
    }

    return "";
  }, [editingExtractedContentSchemaValidationResult, localized]);

  return (
    <div className={styles["document-type-panel"]}>
      <div className={styles["document-type-panel-content"]}>
        <TextField
          className={styles["document-type-panel-content-text-field"]}
          labelId="fsl_custom_model.field_schema_editor.document_type.title"
          placeholder={localized(
            "fsl_custom_model.field_schema_editor.document_type.placeholder"
          )}
          value={editingExtractedContentSchema?.name ?? ""}
          onChange={onChange}
          errorMessage={nameFieldError}
        />
        <div className={styles["document-type-panel-content-help-text"]}>
          <FormattedMessage id="fsl_custom_model.field_schema_editor.document_type.help_text" />
        </div>
      </div>
    </div>
  );
}

const TEMPLATE_TYPES: (keyof typeof EXTRACTED_CONTENT_SCHEMA_TEMPLATE)[] = [
  "business_registration",
  "passport",
  "invoice",
  "air_waybill_llm",
  "bank_statement",
  "resume_llm",
  "purchase_order_llm",
  "quotation_llm",
];

function TemplatesPanel(
  props: ReturnType<typeof useFSLFieldSchemaEditorState>
) {
  const {
    onBuildFromScratchClicked,
    selectedTemplate,
    selectTemplate,
    confirmTemplate,
  } = props;

  const { localized } = useLocale();

  const availableTemplates = React.useMemo((): IChoiceGroupOption[] => {
    return TEMPLATE_TYPES.map(type => ({
      key: type,
      text: localized(
        `fsl_custom_model.field_schema_editor.fields.extractor.${type}`
      ),
    }));
  }, [localized]);

  const onChoiceChanged = React.useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      option?: IChoiceGroupOption
    ) => {
      ev?.stopPropagation();
      ev?.preventDefault();

      if (option) {
        selectTemplate(option.key);
      }
    },
    [selectTemplate]
  );

  return (
    <div className={styles["templates-panel"]}>
      <div className={styles["template-panel-heading"]}>
        <FormattedMessage id="fsl_custom_model.field_schema_editor.templates_panel.title" />
      </div>
      <DangerButton
        textId="fsl_custom_model.field_schema_editor.templates_panel.build_from_scratch_button"
        onClick={onBuildFromScratchClicked}
        className={styles["full-width-button"]}
      />
      <div className={styles["help-text"]}>
        <FormattedMessage id="fsl_custom_model.field_schema_editor.templates_panel.help_text" />
      </div>
      <ChoiceGroup
        options={availableTemplates}
        className={styles["templates-choice-group"]}
        onChange={onChoiceChanged}
      />
      <div>
        <DangerButton
          textId={
            selectedTemplate === undefined
              ? "fsl_custom_model.field_schema_editor.templates_panel.select_template_button"
              : "fsl_custom_model.field_schema_editor.templates_panel.cont_button"
          }
          textValues={{
            template: selectedTemplate?.name ?? "",
          }}
          disabled={selectedTemplate === undefined}
          className={styles["full-width-button"]}
          onClick={confirmTemplate}
        />
      </div>
    </div>
  );
}

function NoFieldPlaceholder() {
  return (
    <div className={styles["no-field-placeholder"]}>
      <FormattedMessage id="fsl_custom_model.field_schema_editor.no_field_panel.placeholder" />
    </div>
  );
}

function FieldsPanel(props: ReturnType<typeof useFSLFieldSchemaEditorState>) {
  const {
    editingExtractedContentSchema,
    selectedEditingSchemaFieldIndex,
    addEditingSchemaField,
  } = useFSLCustomModelEditor();

  const { editingExtractedContentSchemaValidationResult } = props;

  const payloadCount = editingExtractedContentSchema?.payload.length ?? 0;
  const validationResultAccessor =
    new ExtractedContentSchemaValidationResultAccessor(
      editingExtractedContentSchemaValidationResult
    );

  return (
    <div className={styles["fields-panel"]}>
      <div className={styles["fields-panel-content"]}>
        <DangerButton
          id={ButtonId.ManageFieldsAddNewField}
          textId="fsl_custom_model.field_schema_editor.fields.add_new_field"
          onClick={addEditingSchemaField}
        />
        {payloadCount > 0 && (
          <div
            className={classnames(
              styles["fields-panel-content-scrollable"],
              styles["fields-panel-content-field-list"]
            )}
          >
            {editingExtractedContentSchema?.payload.map((field, index) => {
              const result = validationResultAccessor.getPayloadResultFromId(
                field.id
              );

              const subFields = new ExtractedContentSchemaAccessor(
                editingExtractedContentSchema
              )
                .getPayloadSubFields(index)
                .filter(field => field.name !== "");

              return (
                <FSLFieldItem
                  name={field.name}
                  type={field.type}
                  isList={field.isList}
                  key={index}
                  index={index}
                  hasError={result.hasError}
                  isSelected={index === selectedEditingSchemaFieldIndex}
                  subFields={subFields}
                />
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

interface useNotSavedPromptDialogHandleProps {
  isExtractedContentSchemaChanged: boolean;
}

function useNotSavedPromptDialogHandle(
  props: useNotSavedPromptDialogHandleProps
) {
  const { localized } = useLocale();

  const { isExtractedContentSchemaChanged } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const close = React.useCallback(() => {
    setIsDialogOpen(false);
  }, [setIsDialogOpen]);

  const [allowedNextPath, setAllowedNextPath] = React.useState<string>("");
  const navigate = useNavigate();
  const { requestToSaveSchema } = useFSLCustomModelEditor();

  const [navigateToNext, setNavigateToNext] =
    React.useState<() => void | undefined>();

  const open = React.useCallback(
    (next: string | (() => void)) => {
      setIsDialogOpen(true);
      if (typeof next === "string" || next instanceof String) {
        setNavigateToNext(() => () => {
          const nextPath = next as string;
          setAllowedNextPath(nextPath);
          setTimeout(() => {
            navigate(nextPath);
          }, 0);
        });
      } else {
        setNavigateToNext(() => () => {
          setTimeout(() => {
            next();
          });
        });
      }
    },
    [setIsDialogOpen, navigate, setNavigateToNext]
  );

  React.useEffect(() => {
    const handleBeforeunload = (event: BeforeUnloadEvent): void | string => {
      if (isExtractedContentSchemaChanged) {
        // Most modern browsers ignore this message
        const prompt = localized(
          "form_editor.form_not_saved_prompt.save_warning"
        );
        event.returnValue = prompt;
        return prompt;
      }
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, [localized, isExtractedContentSchemaChanged]);

  const blocker = React.useCallback(
    (args: { nextLocation: Location }) => {
      const { nextLocation } = args;

      const notAllowed =
        isExtractedContentSchemaChanged &&
        nextLocation.pathname !== allowedNextPath;
      setAllowedNextPath("");

      if (notAllowed) {
        open(nextLocation.pathname);
        return true;
      }
      return false;
    },
    [isExtractedContentSchemaChanged, allowedNextPath, open]
  );

  useBlocker(blocker);

  const closeAndNavigateToNextPath = React.useCallback(() => {
    close();
    navigateToNext?.();
  }, [close, navigateToNext]);

  const requestToSaveAndNavigateToNextPath = React.useCallback(() => {
    close();
    requestToSaveSchema(() => {
      navigateToNext?.();
    });
  }, [close, requestToSaveSchema, navigateToNext]);

  return React.useMemo(
    () => ({
      isDialogOpen,
      open,
      close,
      closeAndNavigateToNextPath,
      requestToSaveAndNavigateToNextPath,
    }),
    [
      isDialogOpen,
      open,
      close,
      requestToSaveAndNavigateToNextPath,
      closeAndNavigateToNextPath,
    ]
  );
}

function NotSavedPromptDialog(
  props: ReturnType<typeof useNotSavedPromptDialogHandle>
) {
  const {
    isDialogOpen,
    close,
    closeAndNavigateToNextPath,
    requestToSaveAndNavigateToNextPath,
  } = props;
  const { localized } = useLocale();

  const modalProps: IModalProps = React.useMemo(
    () => ({
      isBlocking: true,
      onDismissed: close,
    }),
    [close]
  );

  const dialogContentProps: IDialogContentProps = React.useMemo(
    () => ({
      type: DialogType.normal,
      title: localized("form_editor.form_not_saved_prompt.title"),
      subText: localized("form_editor.form_not_saved_prompt.save_warning"),
    }),
    [localized]
  );

  return (
    <Dialog
      minWidth={405}
      hidden={!isDialogOpen}
      onDismiss={close}
      modalProps={modalProps}
      dialogContentProps={dialogContentProps}
    >
      <div className={styles["not-saved-prompt-dialog-button-actions"]}>
        <ActionButton onClick={close} textId="common.cancel" />
        <DefaultButton
          onClick={closeAndNavigateToNextPath}
          textId={"form_editor.form_not_saved_prompt.dont_save"}
        />
        <PrimaryButton
          onClick={requestToSaveAndNavigateToNextPath}
          textId={"form_editor.form_not_saved_prompt.save_changes"}
        />
      </div>
    </Dialog>
  );
}

export function useFSLFieldSchemaEditorState() {
  const {
    isExtractedContentSchemaChanged,
    editingExtractedContentSchema,
    editingExtractedContentSchemaValidationResult,
    createExtractedContentSchema,
    updateEditingExtractedContentSchema,
    backToMain,
    manageFieldGuidanceEnabled,
  } = useFSLCustomModelEditor();
  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const [selectedTemplate, setSelectedTemplate] = React.useState<
    ExtractedContentSchema | undefined
  >();

  const selectTemplate = React.useCallback((key: string) => {
    const type = key as keyof typeof EXTRACTED_CONTENT_SCHEMA_TEMPLATE;
    const schema = EXTRACTED_CONTENT_SCHEMA_TEMPLATE[type];

    setSelectedTemplate(schema as ExtractedContentSchema);
  }, []);

  const deselectTemplate = React.useCallback(() => {
    setSelectedTemplate(undefined);
  }, [setSelectedTemplate]);

  const confirmTemplate = React.useCallback(() => {
    if (selectedTemplate) {
      createExtractedContentSchema(selectedTemplate);
      deselectTemplate();
    }
  }, [selectedTemplate, createExtractedContentSchema, deselectTemplate]);

  const [buildFromScratchClicked, setBuildFromScratchClicked] =
    React.useState(false);

  const onBuildFromScratchClicked = React.useCallback(() => {
    setBuildFromScratchClicked(true);
  }, []);

  const fieldCount = editingExtractedContentSchema?.payload.length ?? 0;

  const wizardVisible = fieldCount === 0 && !buildFromScratchClicked;

  const [wizardCounter, setWizardCounter] = React.useState(0);

  const requestToDiscardSchema = React.useCallback(async () => {
    const confirm = await requestUserConfirmation(
      {
        titleId: "fsl_custom_model.field_schema_editor.discard_dialog.title",
        messageId:
          "fsl_custom_model.field_schema_editor.discard_dialog.message",
        actionId: "fsl_custom_model.field_schema_editor.discard_dialog.action",
        type: ConfirmModalType.Normal,
      },
      false
    );
    if (confirm) {
      if (wizardVisible) {
        setWizardCounter(c => c + 1);
        setSelectedTemplate(undefined);
        updateEditingExtractedContentSchema(undefined);
      } else {
        backToMain();
      }
    }
  }, [
    backToMain,
    requestUserConfirmation,
    updateEditingExtractedContentSchema,
    wizardVisible,
  ]);

  const notSavedPromptDialogHandle = useNotSavedPromptDialogHandle({
    isExtractedContentSchemaChanged,
  });

  return React.useMemo(() => {
    return {
      wizardVisible,
      onBuildFromScratchClicked,
      editingExtractedContentSchemaValidationResult,
      notSavedPromptDialogHandle,
      selectTemplate,
      selectedTemplate,
      deselectTemplate,
      confirmTemplate,
      wizardCounter,
      requestToDiscardSchema,
      manageFieldGuidanceEnabled,
    };
  }, [
    wizardVisible,
    onBuildFromScratchClicked,
    editingExtractedContentSchemaValidationResult,
    notSavedPromptDialogHandle,
    selectTemplate,
    selectedTemplate,
    deselectTemplate,
    confirmTemplate,
    wizardCounter,
    requestToDiscardSchema,
    manageFieldGuidanceEnabled,
  ]);
}

type SplitViewProps = {
  left: React.ReactNode;
  right: React.ReactNode;
};

export function SplitView(props: SplitViewProps) {
  const { left, right } = props;

  return (
    <div className={styles["split-view"]}>
      <div className={styles["split-view-left"]}>{left}</div>
      <div className={styles["split-view-right"]}>{right}</div>
    </div>
  );
}

export function TemplatesWizard(
  props: ReturnType<typeof useFSLFieldSchemaEditorState>
) {
  const { selectedTemplate } = props;

  return (
    <SplitView
      left={<TemplatesPanel {...props} />}
      right={
        selectedTemplate === undefined ? (
          <NoFieldPlaceholder />
        ) : (
          <div className={styles["template-preview"]}>
            <div className={styles["template-preview-scrollable"]}>
              <div>
                <div className={styles["template-preview-title"]}>
                  {selectedTemplate.name}
                </div>
                <FSLFieldSchemaList
                  extractedContentSchema={selectedTemplate}
                  nameVisible={false}
                />
              </div>
            </div>
          </div>
        )
      }
    ></SplitView>
  );
}

function EditorPanel(
  props: ReturnType<typeof useFSLFieldSchemaEditorState> & {
    editingExtractedContentSchemaValidationResult: ExtractedContentSchemaValidationResult;
  }
) {
  const { selectedEditingSchemaFieldIndex } = useFSLCustomModelEditor();

  const { editingExtractedContentSchemaValidationResult } = props;

  return (
    <SplitView
      left={
        <div className={styles["editor-panel"]}>
          <DocumentTypePanel
            editingExtractedContentSchemaValidationResult={
              editingExtractedContentSchemaValidationResult
            }
          />
          <div className={styles["editor-panel-content-divider"]}></div>
          <FieldsPanel {...props} />
        </div>
      }
      right={
        <>
          {selectedEditingSchemaFieldIndex !== undefined ? (
            <FieldItemEditPanel
              key={selectedEditingSchemaFieldIndex}
              editingExtractedContentSchemaValidationResult={
                editingExtractedContentSchemaValidationResult
              }
            />
          ) : null}
        </>
      }
    />
  );
}
export function FSLFieldSchemaEditorImpl(
  props: ReturnType<typeof useFSLFieldSchemaEditorState>
) {
  const {
    isExtractedContentSchemaChanged,
    requestToSaveSchema,
    backToMain,
    submitManageFieldSupportRequest,
  } = useFSLCustomModelEditor();

  const {
    editingExtractedContentSchemaValidationResult,
    notSavedPromptDialogHandle,
    requestToDiscardSchema,
    wizardCounter,
  } = props;

  const saveDisabled = !isExtractedContentSchemaChanged;

  const onSaveClick = React.useCallback(() => {
    requestToSaveSchema(() => {
      backToMain();
    });
  }, [requestToSaveSchema, backToMain]);

  return (
    <div className={styles["field-schema-editor"]}>
      <div className={styles["field-schema-editor-panel-content"]}>
        <PageHeader
          title="fsl_custom_model.field_schema_editor.page_header.title"
          subtitle="fsl_custom_model.field_schema_editor.page_header.desc"
          className={styles["field-schema-editor-panel-header"]}
          right={
            <div className={styles["field-schema-editor-page-header-buttons"]}>
              <DefaultButton
                textId="common.discard"
                onClick={requestToDiscardSchema}
              />
              <DangerButton
                id={ButtonId.ManageFieldsSaveSchema}
                textId="fsl_custom_model.field_schema_editor.save_schema"
                disabled={saveDisabled}
                onClick={onSaveClick}
              />
            </div>
          }
        />
        {props.wizardVisible ? (
          <TemplatesWizard {...props} key={wizardCounter} />
        ) : (
          <EditorPanel
            {...props}
            editingExtractedContentSchemaValidationResult={
              editingExtractedContentSchemaValidationResult
            }
          />
        )}
      </div>
      <ManageFieldsGuidance
        isFrozen={!props.manageFieldGuidanceEnabled}
        onSubmit={submitManageFieldSupportRequest}
      />
      <NotSavedPromptDialog {...notSavedPromptDialogHandle} />
    </div>
  );
}

export function FSLFieldSchemaEditor() {
  const props = useFSLFieldSchemaEditorState();
  return <FSLFieldSchemaEditorImpl {...props} />;
}
