import sampleAirwayBill1Preview from "../images/sample/sample-airway-bill-1.jpg";
import sampleAirwayBill1 from "../images/sample/sample-airway-bill-1.pdf";
import samplePurchaseOrder1Preview from "../images/sample/sample-purchase-order-1.jpg";
import samplePurchaseOrder1 from "../images/sample/sample-purchase-order-1.pdf";
import sampleQuotation1Preview from "../images/sample/sample-quotation-1.jpg";
import sampleQuotation1 from "../images/sample/sample-quotation-1.pdf";
import sampleResume1Preview from "../images/sample/sample-resume-1.jpg";
import sampleResume1 from "../images/sample/sample-resume-1.pdf";
import { CustomModelLLMParameters } from "../types/customModel";
import { SampleImage } from "../types/extractor";
import { PrebuiltExtractorDefinition } from "./prebuiltExtractor";

const _TemplatedInstantModelExtractorDefinition = {
  air_waybill_llm: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [
      {
        src: sampleAirwayBill1,
        preview: sampleAirwayBill1Preview,
      },
    ],
  },
  purchase_order_llm: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [
      {
        src: samplePurchaseOrder1,
        preview: samplePurchaseOrder1Preview,
      },
    ],
  },
  quotation_llm: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [
      {
        src: sampleQuotation1,
        preview: sampleQuotation1Preview,
      },
    ],
  },
  resume_llm: {
    icon: "IconFileInvoice",
    extractorCardIcon: "IconExtractorCardInvoice",
    sampleImages: [
      {
        src: sampleResume1,
        preview: sampleResume1Preview,
      },
    ],
  },
};

export const TemplatedInstantModelExtractorDefinition: typeof _TemplatedInstantModelExtractorDefinition & {
  [key in keyof typeof _TemplatedInstantModelExtractorDefinition]: {
    icon: string;
    extractorCardIcon: string;
    sampleImages?: readonly SampleImage[];
    llmParameters?: Partial<CustomModelLLMParameters>;
  };
} = _TemplatedInstantModelExtractorDefinition;

export function isTemplatedInstantModelExtractors(
  extractor: string
): extractor is keyof typeof TemplatedInstantModelExtractorDefinition {
  return extractor in TemplatedInstantModelExtractorDefinition;
}

export const PrebuiltTemplatedExtractorDefinition = (() => {
  return {
    ...PrebuiltExtractorDefinition,
    ...TemplatedInstantModelExtractorDefinition,
  };
})();

export function isPrebuiltTemplatedExtractors(
  extractor: string
): extractor is keyof typeof PrebuiltTemplatedExtractorDefinition {
  return extractor in PrebuiltTemplatedExtractorDefinition;
}

export const PrebuiltTemplatedExtractors: (keyof typeof PrebuiltTemplatedExtractorDefinition)[] =
  Object.keys(PrebuiltTemplatedExtractorDefinition).filter(
    isPrebuiltTemplatedExtractors
  );
