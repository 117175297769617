import {
  DirectionalHint,
  ICalloutProps,
  IconButton,
  PrimaryButton as MSPrimaryButton,
  TeachingBubble,
} from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import cntl from "cntl";
import * as React from "react";

import { useLocale } from "../../contexts/locale";

export type GuidanceStep = {
  id: string | number;
  anchorIds: string[];
  messageId: string;
  directionHint?: DirectionalHint;
};

export function useGuidanceHandle(
  script: GuidanceStep[],
  onStepShown?: (step: GuidanceStep) => void,
  onClose?: (lastStep: GuidanceStep) => void
) {
  const [currentStepIndex, setCurrentStepIndex] = React.useState(-1);
  const [runningScript, setRunningScript] = React.useState<GuidanceStep[]>([]);

  const start = React.useCallback(() => {
    const newRunningScript = script
      .map(step => {
        const anchorsIds = step.anchorIds.filter(anchorId => {
          return document.getElementById(anchorId) != null;
        });

        return {
          ...step,
          anchorIds: anchorsIds.slice(0, 1),
        };
      })
      .filter(step => {
        return step.anchorIds.length > 0;
      });

    if (newRunningScript.length > 0) {
      setCurrentStepIndex(0);
    }
    setRunningScript(newRunningScript);
    onStepShown?.(newRunningScript[0]);
  }, [script, onStepShown]);

  const close = React.useCallback(() => {
    onClose?.(runningScript[currentStepIndex]);
    setCurrentStepIndex(-1);
  }, [currentStepIndex, onClose, runningScript]);

  const next = React.useCallback(() => {
    if (currentStepIndex + 1 >= runningScript.length) {
      close();
    } else {
      const newStepIndex = currentStepIndex + 1;
      onStepShown?.(runningScript[newStepIndex]);
      setCurrentStepIndex(newStepIndex);
    }
  }, [runningScript, currentStepIndex, close, onStepShown]);

  const currentStep = React.useMemo(() => {
    return currentStepIndex >= 0 ? runningScript[currentStepIndex] : undefined;
  }, [currentStepIndex, runningScript]);

  const stepCount = runningScript.length;

  const triggerProps = React.useMemo(() => {
    return {
      currentStep,
      currentStepIndex,
      next,
      close,
      stepCount,
    };
  }, [currentStep, currentStepIndex, next, close, stepCount]);

  return React.useMemo(
    () => ({
      triggerProps,
      start,
    }),
    [triggerProps, start]
  );
}

type GuidanceExtraProps = {
  autoClose?: boolean;
};

export function Guidance(
  props: ReturnType<typeof useGuidanceHandle>["triggerProps"] &
    GuidanceExtraProps
) {
  const { currentStep, currentStepIndex, next, close, stepCount } = props;
  const autoClose = props.autoClose ?? false;

  const calloutProps: ICalloutProps = React.useMemo(
    () => ({
      directionalHint: currentStep?.directionHint,
      gapSpace: 8,
      shouldDismissOnWindowFocus: true,
    }),
    [currentStep?.directionHint]
  );

  const { localized } = useLocale();
  const onDismiss = React.useCallback(() => {
    if (autoClose) {
      close();
    }
  }, [close, autoClose]);

  return (
    <>
      {currentStep !== undefined && (
        <div key={currentStepIndex}>
          <TeachingBubble
            target={`#${currentStep.anchorIds[0]}`}
            calloutProps={calloutProps}
            onDismiss={onDismiss}
          >
            <div>
              <div className="flex flex-row justify-between items-start gap-[16px]">
                <div className="text-[16px]">
                  <FormattedMessage id={currentStep.messageId} />
                </div>

                <IconButton
                  iconProps={{
                    iconName: "ChromeClose",
                  }}
                  styles={{
                    root: [
                      {
                        width: 32,
                        height: 32,
                      },
                      "hover:bg-primary-300",
                    ],
                    icon: {
                      color: "#fff",
                      fontSize: 12,
                    },
                  }}
                  onClick={close}
                />
              </div>
              <div className="flex flex-row justify-between items-center mt-[16px]">
                <div>
                  <FormattedMessage
                    id="guidance.progress"
                    values={{
                      current: currentStepIndex + 1,
                      total: stepCount,
                    }}
                  />
                </div>
                <MSPrimaryButton
                  onClick={next}
                  text={
                    currentStepIndex === stepCount - 1
                      ? localized("common.done")
                      : localized("common.next")
                  }
                  styles={{
                    root: [
                      cntl`bg-[#fff] text-primary-200`,
                      {
                        selectors: {
                          ":hover": {
                            backgroundColor: "#1BA58C", // primary-300
                          },
                        },
                      },
                    ],
                  }}
                />
              </div>
            </div>
          </TeachingBubble>
        </div>
      )}
    </>
  );
}
