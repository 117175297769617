import { Label, Text } from "@fluentui/react";
import { FormattedMessage } from "@oursky/react-messageformat";
import { CardElement } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent } from "@stripe/stripe-js";
import classnames from "classnames";
import React, { FormEvent, useCallback } from "react";
import { useSelector } from "react-redux";

import { CARD_BRAND_ICON_MAP, PLAN_DETAIL_COUNT_MAP } from "../../constants";
import { RootState } from "../../redux/types";
import { Plan } from "../../types/plan";
import { SubscriptionData } from "../../types/user";
import PaymentRequiredMessageBar from "../PaymentRequiredMessageBar";
import { PrimaryButton } from "../WrappedMSComponents/Buttons";
import TextField from "../WrappedMSComponents/TextField";
import styles from "./styles.module.scss";

interface Props {
  subscriptionData?: SubscriptionData;
  plans: Plan[];
  selectedPlanId?: string;
  isSubscribed: boolean;
  isSelectedNewcard: boolean;
  isCardReady: boolean;
  errorId?: string;
  billingEmail: string;
  shouldUpdateEmail: boolean;
  onSelectCard: (isNewCard: boolean) => void;
  onBillingEmailSubmit: () => void;
  onSubmit: () => void;
  onBillingEmailChange: (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
  onCardInputChange: (event: StripeCardElementChangeEvent) => void;
  onUnsubscribe: () => void;
  onSubscribeEnterprisePlanClicked: () => void;
}

interface ServicePlanCardProps {
  isActive: boolean;
  titleId: string;
  priceId: string;
  detailIds: string[];

  subscriptionStatus?: string;
}

interface UpdateBillingInfoSectionProps {
  isCardReady: boolean;
  errorId?: string;
  billingEmail: string;
  shouldUpdateEmail: boolean;
  onBillingEmailSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  onClickNewCard: () => void;
  onCardInputChange: (event: StripeCardElementChangeEvent) => void;
  onBillingEmailChange: (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string | undefined
  ) => void;
}

interface SubscribeStarterPlanSectionProps
  extends UpdateBillingInfoSectionProps {
  paymentMethod?: SubscriptionData["paymentMethod"];
  onClickExistingCard: () => void;

  selectedPlanId?: string;
  isSelectedNewcard: boolean;
}

interface ExistingCardProps {
  paymentMethod: NonNullable<SubscriptionData["paymentMethod"]>;
  hasLeftMargin: boolean;
  onClick: () => void;
}

function ServicePlanCard(props: ServicePlanCardProps) {
  const { titleId, priceId, detailIds, isActive, subscriptionStatus } = props;

  const shouldShowSubscriptionStatus =
    isActive &&
    subscriptionStatus !== undefined &&
    !["active", "incomplete"].includes(subscriptionStatus);

  return (
    <div
      className={classnames(styles["plan-box"], {
        [styles["plan-box-grayed"]]: !isActive,
      })}
    >
      <h4>
        <FormattedMessage id={titleId} />
        {subscriptionStatus && shouldShowSubscriptionStatus && (
          <span>{subscriptionStatus.replace("_", " ")}</span>
        )}
      </h4>
      <div className={styles["price"]}>
        <FormattedMessage id={priceId} />
      </div>
      <div className={styles["details"]}>
        <ul>
          {detailIds.map((detailId, index) => (
            <li key={index}>
              <FormattedMessage id={detailId} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const CardBrandIcon = ({ brand }: { brand: string }) => {
  const cardIcon = CARD_BRAND_ICON_MAP[brand];
  return cardIcon ? (
    <img className={styles["card-icon"]} src={cardIcon} alt="" />
  ) : null;
};

const StripeCardInput = React.memo(
  (props: {
    onClick: () => void;
    onCardInputChange: (event: StripeCardElementChangeEvent) => void;
    hasLeftMargin: boolean;
    errorId?: string;
  }) => {
    const { onClick, onCardInputChange, hasLeftMargin, errorId } = props;
    return (
      <div
        className={classnames(styles["card-input"], {
          [styles["no-left-margin"]]: !hasLeftMargin,
        })}
      >
        <CardElement
          onFocus={onClick}
          options={{ hidePostalCode: true }}
          onChange={onCardInputChange}
        />
        {errorId && (
          <div className={styles["card-errors"]} role="alert">
            <FormattedMessage id={errorId} />
          </div>
        )}
      </div>
    );
  }
);

const ExistingCard = React.memo((props: ExistingCardProps) => {
  const { onClick, paymentMethod, hasLeftMargin } = props;

  return (
    <div
      className={classnames(styles["existing-card"], {
        [styles["no-left-margin"]]: !hasLeftMargin,
      })}
      onClick={onClick}
    >
      <CardBrandIcon brand={paymentMethod.brand} />
      <div className={styles["last4"]}>
        {paymentMethod.last4}
        <FormattedMessage id="payment.last_four_digits" />
      </div>
    </div>
  );
});

const SubscribeStarterPlanSection = React.memo(
  (props: SubscribeStarterPlanSectionProps) => {
    const {
      paymentMethod,
      selectedPlanId,
      errorId,
      isSelectedNewcard,
      isCardReady,
      onSubmit,
      onClickExistingCard,
      onClickNewCard,
      onCardInputChange,
      onBillingEmailChange,
      billingEmail,
    } = props;
    return (
      <div className={styles["section"]}>
        <h3>
          <FormattedMessage id={"payment.subscribe.header"} />
        </h3>
        <div className={styles["description"]}>
          <FormattedMessage id={"payment.plan.starter.desc"} />
        </div>
        <form onSubmit={onSubmit}>
          <div className={styles["payment-info"]}>
            <TextField
              className={styles["header"]}
              type="email"
              labelId="payment.billing_email"
              onChange={onBillingEmailChange}
              value={billingEmail}
            />
            <Label className={styles["header"]}>
              <FormattedMessage id="payment.method.header" />
            </Label>
          </div>

          {paymentMethod && (
            <div
              className={classnames(styles["card-container"], {
                [styles["card-selected"]]: !isSelectedNewcard,
              })}
            >
              <ExistingCard
                onClick={onClickExistingCard}
                hasLeftMargin={true}
                paymentMethod={paymentMethod}
              />
            </div>
          )}
          <div
            className={classnames(
              styles[
                paymentMethod ? "card-container" : "card-container-no-padding"
              ],
              {
                [styles["card-selected"]]: isSelectedNewcard && paymentMethod,
              }
            )}
          >
            <StripeCardInput
              onClick={onClickNewCard}
              onCardInputChange={onCardInputChange}
              hasLeftMargin={!!paymentMethod}
              errorId={errorId}
            />
          </div>

          <div className={styles["button-container"]}>
            <PrimaryButton
              type="submit"
              disabled={
                (!isCardReady && isSelectedNewcard) ||
                selectedPlanId === undefined ||
                (!paymentMethod && !isCardReady)
              }
              textId="payment.plan.subscribe"
            />
          </div>
        </form>
      </div>
    );
  }
);

const SubscribeEnterprisePlanSection = React.memo(
  (props: { onSubscribe: () => void }) => {
    const { onSubscribe } = props;
    return (
      <div className={styles["section"]}>
        <h3>
          <FormattedMessage id={"payment.subscribe.header.enterprise"} />
        </h3>
        <div className={styles["description"]}>
          <FormattedMessage id={"payment.plan.enterprise.desc"} />
        </div>
        <div className={styles["button-container"]}>
          <PrimaryButton
            textId="payment.plan.subscribe.enterprise"
            onClick={onSubscribe}
          />
        </div>
      </div>
    );
  }
);

const BillingInfoSection = React.memo((props: ExistingCardProps) => {
  const subscriptionData = useSelector<RootState, SubscriptionData | undefined>(
    state => state.resourceOwner.subscriptionData
  );
  const [billingEmail, setBillingEmail] = React.useState("");
  const userEmail = useSelector(
    (state: RootState) => state.user.currentUser?.email
  );

  React.useEffect(() => {
    setBillingEmail(
      subscriptionData?.billingEmail
        ? subscriptionData.billingEmail
        : userEmail ?? ""
    );
  }, [subscriptionData, userEmail]);

  return (
    <div className={styles["section"]}>
      <h3>
        <FormattedMessage id={"payment.existing.header"} />
      </h3>
      <div className={styles["payment-info"]}>
        <Label className={styles["header"]}>
          <FormattedMessage id="payment.billing_email" />
        </Label>
        <Text>{billingEmail}</Text>

        <Label className={styles["header"]}>
          <FormattedMessage id="payment.method.header" />
        </Label>
      </div>
      <ExistingCard {...props} />
    </div>
  );
});

const UpdateBillingInfoSection = React.memo(
  (props: UpdateBillingInfoSectionProps) => {
    const {
      errorId,
      isCardReady,
      billingEmail,
      shouldUpdateEmail,
      onSubmit,
      onBillingEmailSubmit,
      onClickNewCard,
      onCardInputChange,
      onBillingEmailChange,
    } = props;

    return (
      <div className={styles["section"]}>
        <h3>
          <FormattedMessage id={"payment.update.header"} />
        </h3>

        <form onSubmit={onBillingEmailSubmit}>
          <div className={styles["payment-info"]}>
            <TextField
              className={styles["header"]}
              type="email"
              labelId="payment.billing_email"
              onChange={onBillingEmailChange}
              value={billingEmail}
            />
          </div>
          <div className={styles["button-container"]}>
            <PrimaryButton
              type="submit"
              disabled={!shouldUpdateEmail}
              textId="payment.update_builling_email"
            />
          </div>
        </form>

        <form onSubmit={onSubmit}>
          <div className={styles["payment-info"]}>
            <Label className={styles["header"]}>
              <FormattedMessage id="payment.method.header" />
            </Label>
          </div>
          <StripeCardInput
            onClick={onClickNewCard}
            onCardInputChange={onCardInputChange}
            hasLeftMargin={false}
            errorId={errorId}
          />
          <div className={styles["button-container"]}>
            <PrimaryButton
              type="submit"
              disabled={!isCardReady}
              textId="common.update"
            />
          </div>
        </form>
      </div>
    );
  }
);

const UnsubscribePlanSection = React.memo(
  (props: { onUnsubscribe: () => void }) => {
    const { onUnsubscribe } = props;
    return (
      <div className={styles["section"]}>
        <h3>
          <FormattedMessage id={"payment.unsubscribe.header"} />
        </h3>
        <div className={styles["button-container"]}>
          <PrimaryButton
            type="submit"
            onClick={onUnsubscribe}
            textId="payment.unsubscribe.button"
          />
        </div>
      </div>
    );
  }
);

const Payment = React.memo((props: Props) => {
  const {
    subscriptionData,
    plans,
    selectedPlanId,
    isSubscribed,
    isSelectedNewcard,
    isCardReady,
    errorId,
    billingEmail,
    shouldUpdateEmail,
    onSelectCard,
    onCardInputChange,
    onUnsubscribe,
    onBillingEmailSubmit,
    onSubmit,
    onSubscribeEnterprisePlanClicked,
    onBillingEmailChange,
  } = props;

  const hasChangeSubscriptionPermission = useSelector<RootState, boolean>(
    (state: RootState) => state.resourceOwner.permissions.editSubscription
  );

  const paymentMethod = subscriptionData?.paymentMethod;

  const isPaymentRequired = useSelector(
    (state: RootState) => state.resourceOwner.isPaymentRequired
  );

  const currentPlan = useSelector(
    (state: RootState) => state.resourceOwner.plan
  );
  const currentPlanId = useSelector(
    (state: RootState) => state.resourceOwner.planId
  );

  const _onBillingEmailSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onBillingEmailSubmit();
    },
    [onBillingEmailSubmit]
  );

  const _onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      e.stopPropagation();
      if (isCardReady || !isSelectedNewcard) {
        onSubmit();
      }
    },
    [isCardReady, isSelectedNewcard, onSubmit]
  );

  const onClickExistingCard = useCallback(() => {
    onSelectCard(false);
  }, [onSelectCard]);
  const onClickNewCard = useCallback(() => {
    onSelectCard(true);
  }, [onSelectCard]);

  return (
    <div className={styles["payment"]}>
      {isPaymentRequired && <PaymentRequiredMessageBar />}
      <div className={styles["section"]}>
        <h3>
          <FormattedMessage id="payment.plan.header" />
        </h3>
        <div className={styles["plan-container"]}>
          {plans.map(plan => (
            <ServicePlanCard
              key={plan.id}
              isActive={
                currentPlan === "quota_plan"
                  ? currentPlanId === plan.id
                  : plan.type === (currentPlan || "free")
              }
              subscriptionStatus={
                currentPlan === "quota_plan" && subscriptionData
                  ? subscriptionData.subscriptionStatus
                  : undefined
              }
              titleId={`payment.plan.${plan.name}.title`}
              priceId={`payment.plan.${plan.name}.price`}
              detailIds={Array.from(
                { length: PLAN_DETAIL_COUNT_MAP[plan.name] as number },
                (_, i) => `payment.plan.${plan.name}.detail${i + 1}`
              )}
            />
          ))}
        </div>
      </div>

      {hasChangeSubscriptionPermission && (
        <div>
          {!isSubscribed && (
            <SubscribeStarterPlanSection
              paymentMethod={paymentMethod}
              selectedPlanId={selectedPlanId}
              isSelectedNewcard={isSelectedNewcard}
              isCardReady={isCardReady}
              errorId={errorId}
              billingEmail={billingEmail}
              shouldUpdateEmail={shouldUpdateEmail}
              onCardInputChange={onCardInputChange}
              onSubmit={_onSubmit}
              onBillingEmailSubmit={_onBillingEmailSubmit}
              onClickExistingCard={onClickExistingCard}
              onClickNewCard={onClickNewCard}
              onBillingEmailChange={onBillingEmailChange}
            />
          )}

          <SubscribeEnterprisePlanSection
            onSubscribe={onSubscribeEnterprisePlanClicked}
          />

          {isSubscribed && paymentMethod && (
            <>
              <BillingInfoSection
                paymentMethod={paymentMethod}
                hasLeftMargin={false}
                onClick={onClickExistingCard}
              />
              <UpdateBillingInfoSection
                isCardReady={isCardReady}
                errorId={errorId}
                billingEmail={billingEmail}
                shouldUpdateEmail={shouldUpdateEmail}
                onBillingEmailChange={onBillingEmailChange}
                onCardInputChange={onCardInputChange}
                onBillingEmailSubmit={_onBillingEmailSubmit}
                onSubmit={_onSubmit}
                onClickNewCard={onClickNewCard}
              />
              <UnsubscribePlanSection onUnsubscribe={onUnsubscribe} />
            </>
          )}
        </div>
      )}
    </div>
  );
});

export default Payment;
