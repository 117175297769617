import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router";

import { useTeamActionCreator } from "../actions/team";
import { useUserActionCreator } from "../actions/user";
import LoadingModal from "../components/LoadingModal";
import { OnboardingWizard } from "../components/v2/OnboardingWizard";
import { AppConfig } from "../config";
import { LocalStorageKeys } from "../constants/localStorage";
import errors, { FOCRError } from "../errors";
import { useGeoIP } from "../hooks/geoip";
import { useGetTeamInvitationsFromAllRegions } from "../hooks/invitation";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { useRedirectToRegionForTeam } from "../hooks/region";
import { useToast } from "../hooks/toast";
import { PathParam } from "../models";
import { SurveyForm } from "../types/survey";

function useSurveyForm(
  defaultName?: string,
  hasPendingInvitation: boolean = false
) {
  const toast = useToast();
  const userInfo = useAppSelector(state => state.user.userInfo);
  const localStorageKey = [
    LocalStorageKeys.OnboardingSurveyFormKey,
    userInfo?.sub,
  ].join("-");

  const [isSubmittedResult, setIsSubmittedResult] = useState(false);
  const [isSubmittingResult, setIsSubmittingResult] =
    React.useState<boolean>(false);

  const { ip, countryCode } = useGeoIP();

  const [surveyForm, setSurveyForm] = React.useState<SurveyForm>(() => {
    const defaults = {
      name: defaultName ?? "",
      companyName: "",
      reason: "",
      phoneNumber: "",
      useCases: [],
    } as SurveyForm;
    const storedForm = localStorage.getItem(localStorageKey);
    if (storedForm) {
      try {
        const form = JSON.parse(storedForm);
        return {
          ...defaults,
          ...form,
        };
      } catch (e) {}
    }
    return defaults;
  });
  const surveyFormRef = React.useRef(surveyForm);
  surveyFormRef.current = surveyForm;

  const updateSurveyForm = React.useCallback(
    (form: SurveyForm) => {
      localStorage.setItem(localStorageKey, JSON.stringify(form));
      setSurveyForm(form);
      surveyFormRef.current = form;
    },
    [localStorageKey]
  );

  const navigate = useNavigate();

  const { submitSurvey: submitSurveyAction } = useUserActionCreator();

  const submitSurvey = React.useCallback(async () => {
    if (isSubmittingResult) {
      return;
    }
    setIsSubmittingResult(true);

    try {
      await submitSurveyAction(surveyFormRef.current, ip ?? "");
      localStorage.removeItem(localStorageKey);
      setIsSubmittedResult(true);
      if (hasPendingInvitation) {
        navigate("/onboarding/team_invitation");
      } else {
        navigate("/onboarding/team_creation");
      }
    } catch (e) {
      console.error(e);
      toast.error("error.unknown_error");
    } finally {
      setIsSubmittingResult(false);
    }
  }, [
    isSubmittingResult,
    toast,
    localStorageKey,
    ip,
    navigate,
    hasPendingInvitation,
    submitSurveyAction,
  ]);

  const isSurveyAlreadySubmitted =
    userInfo?.customAttributes["survey_form"] !== undefined;

  const isSurveySubmitted = isSurveyAlreadySubmitted || isSubmittedResult;
  const isSurveyWizardLoading = isSubmittingResult;

  return {
    surveyForm,
    updateSurveyForm,
    isSurveySubmitted,
    submitSurvey,
    isSubmittingResult,
    isSurveyWizardLoading,
    countryCode,
  };
}

export default function OnboardingContainer() {
  const [isCreatingTeam, setIsCreatingTeam] = useState(false);

  const { page } = useUnsafeParams<PathParam>();

  const { createTeamRetryable } = useTeamActionCreator();
  const toast = useToast();
  const navigate = useNavigate();

  const getInvitationState = useGetTeamInvitationsFromAllRegions();
  const hasPendingInvitation =
    getInvitationState.type === "success"
      ? getInvitationState.hasPendingInvitation
      : undefined;

  const userInfo = useAppSelector(state => state.user.userInfo);

  const {
    surveyForm,
    updateSurveyForm,
    isSurveySubmitted,
    isSurveyWizardLoading,
    submitSurvey,
    countryCode,
  } = useSurveyForm(userInfo?.name, hasPendingInvitation ?? false);

  const isLoading = getInvitationState.type === "loading";

  const { redirectToRegionForTeam } = useRedirectToRegionForTeam();

  const onConfirmTeamCreation = useCallback(
    async (name: string, region: string, preferredLookupId: string) => {
      setIsCreatingTeam(true);

      const { region: currentRegion } = AppConfig;

      try {
        const { lookupId } = await createTeamRetryable(
          name,
          region,
          preferredLookupId
        );

        if (currentRegion !== region) {
          redirectToRegionForTeam(region, lookupId);
        } else {
          navigate("/onboarding/extractor_create");
        }
      } catch (e) {
        if (e instanceof FOCRError && e !== errors.UnknownError) {
          toast.error(e.messageId);
        } else {
          toast.error("error.create_team.failed");
        }
      } finally {
        setIsCreatingTeam(false);
      }
    },
    [
      createTeamRetryable,
      setIsCreatingTeam,
      toast,
      redirectToRegionForTeam,
      navigate,
    ]
  );

  const viewTeam = useCallback(
    (teamId: string, region: string) => {
      if (region === AppConfig.region) {
        navigate(`/team/${teamId}`);
      } else {
        redirectToRegionForTeam(region, teamId);
      }
    },
    [navigate, redirectToRegionForTeam]
  );

  return (
    <div className="h-lvh">
      <OnboardingWizard
        surveyForm={surveyForm}
        updateSurveyForm={updateSurveyForm}
        submitSurvey={submitSurvey}
        countryCode={countryCode}
        isLoading={isLoading}
        page={page}
        isSurveyFormSubmitted={isSurveySubmitted}
        hasPendingInvitation={hasPendingInvitation ?? false}
        onConfirmTeamCreation={onConfirmTeamCreation}
        onViewTeam={viewTeam}
      />
      <LoadingModal isOpen={isSurveyWizardLoading || isLoading} />
      <LoadingModal isOpen={isCreatingTeam} messageId={"team.creating_team"} />
    </div>
  );
}
