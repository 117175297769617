import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useExtractionActionCreator } from "../actions/extraction";
import { useWorkspaceActionCreator } from "../actions/workspace";
import ErrorPlaceholder from "../components/ErrorPlaceholder";
import { Layout, Main, Top } from "../components/Layout";
import LoadingModal from "../components/LoadingModal";
import { WorkspaceDocumentSection } from "../components/WorkspaceDocument";
import { WorkspaceTab } from "../components/WorkspaceNavBar";
import { SUPPORTED_EXTRACT_MIME } from "../constants";
import {
  EXTRACTION_MAXIMUM_POLLING_TIME,
  EXTRACTION_PAGE_SIZE,
  EXTRACTION_POLLING_INTERVAL,
} from "../constants/layout";
import errors, { FOCRError } from "../errors";
import { useWorkerToken } from "../hooks/app";
import { PendingState, useMergeableAsyncCallback } from "../hooks/common";
import { useUnsafeParams } from "../hooks/params";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { useCommonWorkspaceContainerState } from "../hooks/workspace";
import { PathParam } from "../models";
import {
  ListExtractionsParams,
  UploadQueueEntry,
} from "../reducers/extraction";
import { RootState } from "../redux/types";
import { SortOrder } from "../types/api";
import { ConfirmModalType } from "../types/confirmation";
import {
  ExtractionFilterableField,
  ExtractionResult,
  PaginatedExtractions,
} from "../types/extraction";
import { getFileTypeWithDetection } from "../utils/file";
import HeaderContainer from "./Header";
import { WorkspaceNavBarLayoutContainer as WorkspaceNavBarLayout } from "./WorkspaceNavBarLayout";

const listExtractionsPendingState: PendingState<
  PaginatedExtractions,
  [
    Parameters<
      ReturnType<typeof useExtractionActionCreator>["listExtractions"]
    >[0]
  ]
> = {
  isRunning: false,
  promises: [],
  params: null,
};

function useWorkspaceDocumentContainer() {
  const toast = useToast();
  const { workspaceId } = useUnsafeParams<PathParam>();
  const { workspace } = useCommonWorkspaceContainerState(workspaceId);
  const {
    listExtractions: _listExtractions,
    listProcessingExtractions,
    createExtraction,
    cleanupUploadQueue,
    exportExtractions,
    exportAllExtractions,
    deleteExtraction,
    deleteExtractionResults,
    deleteAllExtractions,
    retryExtraction,
    retryAllFailedExtractions,
    reExtractExtraction,
    clearListParamsShouldOverrideUrl,
  } = useExtractionActionCreator();
  const { setParam, setParams } = useSearchParamUtils();
  const [searchParam] = useSearchParams();

  const listParams = useMemo<ListExtractionsParams>(
    () => ({
      page: parseInt(searchParam.get(URLParamsKey.page) ?? "1") ?? 1,
      size: EXTRACTION_PAGE_SIZE,
      fileName: searchParam.get(URLParamsKey.search) ?? "",
      sort: {
        field:
          (searchParam.get(
            URLParamsKey.sortColumn
          ) as ExtractionFilterableField) ?? "createdAt",
        order: (searchParam.get(URLParamsKey.sortOrder) as SortOrder) ?? "desc",
      },
    }),
    [searchParam]
  );

  const listParamsRef = useRef(listParams);
  useEffect(() => {
    listParamsRef.current = listParams;
  }, [listParams]);

  // Do not show loading spinner if query result set does not change (e.g. refresh / change sort)
  const [shouldShowLoading, setShouldShowLoading] = useState(false);
  const lastQueryKeyRef = useRef(
    `${workspaceId}/${listParams.fileName}/${listParams.page}`
  );
  const _listExtractionsAndSetShowLoading = useCallback(
    async (
      args: Parameters<
        ReturnType<typeof useExtractionActionCreator>["listExtractions"]
      >[0]
    ): ReturnType<typeof _listExtractions> => {
      const queryKey = `${args.workspaceId}/${args.fileName}/${args.page}`;
      setShouldShowLoading(lastQueryKeyRef.current !== queryKey);
      lastQueryKeyRef.current = queryKey;
      return await _listExtractions(args);
    },
    [_listExtractions]
  );

  // debounce list extractions call
  const listExtractionsDebounced = useMergeableAsyncCallback(
    () => listExtractionsPendingState,
    _listExtractionsAndSetShowLoading,
    [_listExtractionsAndSetShowLoading]
  );

  const listExtractions = React.useCallback(
    (
      args: Parameters<
        ReturnType<typeof useExtractionActionCreator>["listExtractions"]
      >[0]
    ) => {
      listExtractionsDebounced(args).catch(e => {
        if (e instanceof FOCRError) {
          if (e.messageId === "error.workspace_not_found") {
            return;
          }
          toast.error(e.messageId, e.detail);
        } else {
          toast.error("error.failed_to_list_extractions");
        }
      });
    },
    [listExtractionsDebounced, toast]
  );

  // resolve conflict between list params in redux and url param
  const { listParamsByWorkspace } = useAppSelector(state => state.extraction);
  useEffect(() => {
    const listParamsInRedux = listParamsByWorkspace[workspaceId];
    const hasConflict =
      listParamsInRedux != null &&
      (listParamsInRedux.page !== listParams.page ||
        listParamsInRedux.size !== listParams.size ||
        listParamsInRedux.fileName !== listParams.fileName ||
        listParamsInRedux.sort.field !== listParams.sort.field ||
        listParamsInRedux.sort.order !== listParams.sort.order);
    const shouldOverrideUrlState =
      listParamsInRedux?.shouldOverrideUrlState ?? false;

    if (listParamsInRedux == null || (hasConflict && !shouldOverrideUrlState)) {
      // restore redux state from url param or fallback to defaults
      // this is the case for navigation via screen interaction
      // or browser navigation (forward / backward /direct url)
      listExtractions({
        workspaceId,
        page: listParams.page,
        size: listParams.size,
        fileName: listParams.fileName,
        sort: listParams.sort,
      });
      listProcessingExtractions({ workspaceId });
    } else if (hasConflict && shouldOverrideUrlState) {
      // this case is most likely when we paginated the list via detail screen
      setParams(
        new Map([
          [URLParamsKey.page, listParamsInRedux.page.toFixed(0)],
          [URLParamsKey.search, listParamsInRedux.fileName],
          [URLParamsKey.sortColumn, listParamsInRedux.sort.field],
          [URLParamsKey.sortOrder, listParamsInRedux.sort.order],
        ])
      );
      clearListParamsShouldOverrideUrl(workspaceId);
      listProcessingExtractions({ workspaceId });
    }
  }, [
    clearListParamsShouldOverrideUrl,
    listExtractions,
    listParams,
    listParams.fileName,
    listParams.page,
    listParams.sort,
    listParamsByWorkspace,
    listProcessingExtractions,
    searchParam,
    setParams,
    workspaceId,
  ]);

  const refreshPage = useCallback(() => {
    const { fileName, page, sort } = listParamsRef.current;
    listExtractions({
      workspaceId,
      page,
      size: EXTRACTION_PAGE_SIZE,
      fileName,
      sort,
    });
    listProcessingExtractions({ workspaceId });
  }, [listExtractions, workspaceId, listProcessingExtractions]);

  const onPageChange = useCallback(
    (newPage: number) => {
      setParam(URLParamsKey.page, newPage.toString());
    },
    [setParam]
  );

  const onFileNameSearch = useCallback(
    (searchText: string) => {
      setParams(
        new Map([
          [URLParamsKey.search, searchText],
          [URLParamsKey.page, "1"],
        ])
      );
    },
    [setParams]
  );

  const onColumnSort = useCallback(
    ({
      field,
      order,
    }: {
      field: ExtractionFilterableField;
      order: SortOrder;
    }) => {
      setParams(
        new Map([
          [URLParamsKey.sortColumn, field],
          [URLParamsKey.sortOrder, order],
          [URLParamsKey.page, "1"],
        ])
      );
    },
    [setParams]
  );

  const onExportSelected = useCallback(
    async (resultIndexesByExtractionId: Record<string, number[]>) => {
      try {
        await exportExtractions({
          workspaceId,
          resultIndexesByExtractionId,
        });
      } catch (e: unknown) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          toast.error(
            errors.UnknownError.messageId,
            undefined,
            errors.UnknownError.detail
          );
        }
      }
    },
    [exportExtractions, toast, workspaceId]
  );

  const onExportAll = useCallback(async () => {
    try {
      await exportAllExtractions({ workspaceId });
    } catch (e: unknown) {
      if (e instanceof FOCRError) {
        toast.error(e.messageId, undefined, e.detail);
      } else {
        toast.error(
          errors.UnknownError.messageId,
          undefined,
          errors.UnknownError.detail
        );
      }
    }
  }, [exportAllExtractions, toast, workspaceId]);

  const {
    isListingByWorkspace,
    paginatedExtractionsByWorkspace,
    listErrorByWorkspace,
    processingExtractionsByWorkspace,
    processingExtractionResultsByWorkspace,
    uploadQueueByWorkspace,
    uploadingCountByWorkspace,
    isExportingByWorkspace,
    isInitiallyEmptyByWorkspace,
  } = useSelector((state: RootState) => state.extraction);

  // Fallback to last page if not first page currently and list is empty
  useEffect(() => {
    if (isListingByWorkspace[workspaceId] ?? false) {
      return;
    }
    const paginatedExtractions = paginatedExtractionsByWorkspace[workspaceId];
    if (paginatedExtractions == null) {
      return;
      // no data from api, no-op
    }
    if (paginatedExtractions.extractions.length > 0) {
      return;
      // current page has content, no-op
    }
    if ((listParamsByWorkspace[workspaceId]?.page ?? 1) <= 1) {
      return;
      // current page has no content but is already on first page, no-op
    }
    // navigate to valid last page
    const lastPage = Math.max(
      1,
      Math.ceil(paginatedExtractions.pageInfo.totalCount / EXTRACTION_PAGE_SIZE)
    );
    onPageChange(lastPage);
  }, [
    isListingByWorkspace,
    listParamsByWorkspace,
    onPageChange,
    paginatedExtractionsByWorkspace,
    workspaceId,
  ]);

  const uploadQueue = useMemo(
    () => uploadQueueByWorkspace[workspaceId] ?? {},
    [uploadQueueByWorkspace, workspaceId]
  );

  // poll page if there is pending extractions
  const [shouldPollPage, setShouldPollPage] = useState(false);

  useEffect(() => {
    if (listErrorByWorkspace[workspaceId] != null) {
      setShouldPollPage(false);
    }
    const isExtracting =
      (processingExtractionsByWorkspace[workspaceId] ?? []).length +
        (processingExtractionResultsByWorkspace[workspaceId] ?? []).length >
      0;
    setShouldPollPage(isExtracting);
  }, [
    paginatedExtractionsByWorkspace,
    listErrorByWorkspace,
    workspaceId,
    processingExtractionsByWorkspace,
    processingExtractionResultsByWorkspace,
  ]);

  const pollingTimeElapsedRef = useRef(0);

  useEffect(() => {
    if (!shouldPollPage) {
      return () => {};
    }
    const interval = setInterval(() => {
      refreshPage();
      if (pollingTimeElapsedRef.current > EXTRACTION_MAXIMUM_POLLING_TIME) {
        setShouldPollPage(false);
      }
      pollingTimeElapsedRef.current += EXTRACTION_POLLING_INTERVAL;
    }, EXTRACTION_POLLING_INTERVAL);
    return () => {
      clearInterval(interval);
    };
  }, [refreshPage, shouldPollPage]);

  const { token } = useWorkerToken(); // FIXME: use workspace dedicated token

  const sortedUploadQueue = useMemo(() => {
    return Object.values(uploadQueue).sort(
      (a, b) => b.uploadedAt.getTime() - a.uploadedAt.getTime()
    );
  }, [uploadQueue]);

  const isUploadingForOtherWorkspaces = useMemo(() => {
    const currentWorkspaceUploadingCount =
      uploadingCountByWorkspace[workspaceId] ?? 0;
    const otherWorkspaceUploadingCount =
      Object.values(uploadingCountByWorkspace).reduce(
        (prev, curr) => prev + curr,
        0
      ) - currentWorkspaceUploadingCount;
    return (
      currentWorkspaceUploadingCount > 0 && otherWorkspaceUploadingCount > 0
    );
  }, [uploadingCountByWorkspace, workspaceId]);

  const { isUsageReachedHardLimit, isPaymentRequired } = useSelector(
    (state: RootState) => state.resourceOwner
  );

  const isOutOfQuota = isUsageReachedHardLimit || isPaymentRequired;

  const onSelectFiles = useCallback(
    (files: File[]) => {
      if (isOutOfQuota) {
        toast.error("error.usage_reached_hard_limit");
        return;
      }
      files.forEach(file => {
        if (workspace.state !== "success") {
          return;
        }
        if (token == null) {
          return;
        }
        const fileType = getFileTypeWithDetection(file);
        if (SUPPORTED_EXTRACT_MIME.includes(fileType)) {
          createExtraction({
            file,
            workspace: workspace.data,
            token,
          }).then(() => {
            refreshPage();
          });
        } else {
          toast.error(
            "error.workspace.document_upload_unsupported",
            undefined,
            {
              fileType,
            }
          );
        }
      });
    },
    [
      isOutOfQuota,
      toast,
      workspace.state,
      // @ts-expect-error undef if state not success
      workspace.data,
      token,
      createExtraction,
      refreshPage,
    ]
  );

  const onCleanupUploadQueue = useCallback(() => {
    cleanupUploadQueue({ workspaceId: workspaceId });
  }, [cleanupUploadQueue, workspaceId]);

  const [isDeleting, setIsDeleting] = useState(false);

  const { requestUserConfirmation } = useConfirmModalActionCreator();
  const onDeleteExtractionsOrResults = useCallback(
    async (
      items: {
        extractionId: string;
        extractionResult: ExtractionResult | null;
      }[]
    ) => {
      const confirmDelete = await requestUserConfirmation(
        {
          actionId: "workspace.document.table.delete_document.modal.action",
          titleId: "workspace.document.table.delete_document.modal.title",
          titleValues: {
            count: items.length,
          },
          messageId:
            "workspace.document.table.delete_document.modal.description",
          type: ConfirmModalType.Destory,
        },
        false
      );
      // row is not in success state, delete extraction directly
      const toBeDeletedExtractions = items.filter(
        item => item.extractionResult == null
      );
      // row is in success state, delete extraction records
      const toBeDeletedResults = items.filter(
        item => item.extractionResult != null
      ) as {
        extractionId: string;
        extractionResult: ExtractionResult;
      }[];
      if (confirmDelete) {
        try {
          setIsDeleting(true);
          if (toBeDeletedResults.length > 0) {
            await deleteExtractionResults(workspaceId, toBeDeletedResults);
          }
          if (toBeDeletedExtractions.length > 0) {
            await deleteExtraction({
              idTuples: toBeDeletedExtractions.map(e => ({
                workspaceId,
                extractionId: e.extractionId,
              })),
            });
          }
          toast.success(
            "workspace.document.table.delete_document.success.message"
          );
          refreshPage();
        } catch (e: unknown) {
          if (e instanceof FOCRError) {
            toast.error(e.messageId, undefined, e.detail);
          } else {
            console.error(e);
            toast.error(errors.UnknownError.messageId);
          }
        } finally {
          setIsDeleting(false);
        }
      }
    },
    [
      requestUserConfirmation,
      toast,
      refreshPage,
      deleteExtractionResults,
      workspaceId,
      deleteExtraction,
    ]
  );

  const onDeleteAllExtractions = useCallback(async () => {
    const confirmDelete = await requestUserConfirmation(
      {
        actionId: "workspace.document.table.delete_document.modal.action",
        titleId: "workspace.document.table.delete_document.modal.title.all",
        messageId:
          "workspace.document.table.delete_document.modal.description.all",
        type: ConfirmModalType.Destory,
      },
      false
    );

    if (confirmDelete) {
      try {
        setIsDeleting(true);
        await deleteAllExtractions(workspaceId);
        toast.success(
          "workspace.document.table.delete_document.success.message"
        );
        refreshPage();
      } catch (e: unknown) {
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          console.error(e);
          toast.error(errors.UnknownError.messageId);
        }
      } finally {
        setIsDeleting(false);
      }
    }
  }, [
    requestUserConfirmation,
    deleteAllExtractions,
    workspaceId,
    toast,
    refreshPage,
  ]);

  const { isUploadWidgetCollapsed } = useSelector(
    (state: RootState) => state.workspace
  );

  const { toggleIsUploadWidgetCollapsed } = useWorkspaceActionCreator();

  const onRetry = useCallback(
    (entry: UploadQueueEntry) => {
      if (entry.state !== "errored") {
        return;
      }
      if (workspace.state !== "success") {
        return;
      }
      if (token == null) {
        return;
      }
      retryExtraction({
        uploadId: entry.id,
        workspace: workspace.data,
        token: token,
      });
    },
    // @ts-expect-error workspace.data undef if state not success
    [retryExtraction, token, workspace.data, workspace.state]
  );

  const onRetryAllFailed = useCallback(
    () => {
      if (workspace.state !== "success") {
        return;
      }
      if (token == null) {
        return;
      }
      retryAllFailedExtractions({
        workspace: workspace.data,
        token: token,
      });
    },
    // @ts-expect-error workspace.data undef if state not success
    [retryAllFailedExtractions, token, workspace.data, workspace.state]
  );

  const onReExtractExtraction = useCallback(
    async (extractionResult: ExtractionResult) => {
      try {
        if (token == null) {
          throw new Error("token null, should not happen");
        }
        if (extractionResult.id == null) {
          throw new Error("extraction result id null, should not happen");
        }
        await reExtractExtraction(token, workspaceId, extractionResult);
        // NOTE: polling should be triggered by processing record added by action
      } catch (e: unknown) {
        console.error(e);
        if (e instanceof FOCRError) {
          toast.error(e.messageId, undefined, e.detail);
        } else {
          toast.error(
            errors.UnknownError.messageId,
            undefined,
            errors.UnknownError.detail
          );
        }
      }
    },
    [reExtractExtraction, toast, token, workspaceId]
  );

  const onReExtractAllFailedExtractions = useCallback(
    async (extractionResults: ExtractionResult[]) => {
      await Promise.all(extractionResults.map(onReExtractExtraction));
    },
    [onReExtractExtraction]
  );

  return React.useMemo(
    () => ({
      workspaceId,
      workspace,
      isLoading: workspace.state === "loading",
      isExtractionsLoading:
        (isListingByWorkspace[workspaceId] ?? true) && shouldShowLoading,
      extractions: paginatedExtractionsByWorkspace[workspaceId] as
        | PaginatedExtractions
        | undefined,
      processingExtractionResults:
        processingExtractionResultsByWorkspace[workspaceId] ?? [],
      extractionsError: listErrorByWorkspace[workspaceId] as
        | FOCRError
        | undefined,
      onPageChange,
      onExportSelected,
      onExportAll,
      isPreparingExport: isExportingByWorkspace[workspaceId] ?? false,
      currentPage: listParams.page,
      fileNameSearchText: listParams.fileName,
      onFileNameSearch,
      sort: listParams.sort,
      onColumnSort,
      onSelectFiles,
      sortedUploadQueue,
      isUploadingForOtherWorkspaces,
      onCleanupUploadQueue,
      isOutOfQuota,
      onDeleteExtractionsOrResults,
      onDeleteAllExtractions,
      processingCount:
        (processingExtractionsByWorkspace[workspaceId] ?? []).length +
        (processingExtractionResultsByWorkspace[workspaceId] ?? []).length,
      isUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
      isDeleting,
      onRetry,
      onRetryAllFailed,
      onReExtractExtraction,
      onReExtractAllFailedExtractions,
      shouldShowEmptyPlaceholder:
        isInitiallyEmptyByWorkspace[workspaceId] ?? false,
    }),
    [
      workspaceId,
      workspace,
      isListingByWorkspace,
      shouldShowLoading,
      paginatedExtractionsByWorkspace,
      processingExtractionResultsByWorkspace,
      listErrorByWorkspace,
      onPageChange,
      onExportSelected,
      onExportAll,
      isExportingByWorkspace,
      listParams.page,
      listParams.fileName,
      listParams.sort,
      onFileNameSearch,
      onColumnSort,
      onSelectFiles,
      sortedUploadQueue,
      isUploadingForOtherWorkspaces,
      onCleanupUploadQueue,
      isOutOfQuota,
      onDeleteExtractionsOrResults,
      onDeleteAllExtractions,
      processingExtractionsByWorkspace,
      isUploadWidgetCollapsed,
      toggleIsUploadWidgetCollapsed,
      isDeleting,
      onRetry,
      onRetryAllFailed,
      onReExtractExtraction,
      onReExtractAllFailedExtractions,
      isInitiallyEmptyByWorkspace,
    ]
  );
}

export default function WorkspaceDocumentContainer() {
  const props = useWorkspaceDocumentContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        {props.workspace.state === "error" && (
          <ErrorPlaceholder messageId="common.fail_to_fetch_workspace" />
        )}
        {props.workspace.state === "success" && (
          <WorkspaceNavBarLayout selectedTab={WorkspaceTab.Documents}>
            <WorkspaceDocumentSection
              workspace={props.workspace.data}
              extractions={props.extractions ?? null}
              processingExtractionResults={props.processingExtractionResults}
              currentPage={props.currentPage}
              fileNameSearchText={props.fileNameSearchText}
              onFileNameSearch={props.onFileNameSearch}
              sort={props.sort}
              onColumnSort={props.onColumnSort}
              onPageChange={props.onPageChange}
              onExportSelected={props.onExportSelected}
              onExportAll={props.onExportAll}
              isPreparingExport={props.isPreparingExport}
              isLoading={props.isExtractionsLoading}
              error={props.extractionsError ?? null}
              isOutOfQuota={props.isOutOfQuota}
              onSelectFiles={props.onSelectFiles}
              uploadQueue={props.sortedUploadQueue}
              isUploadingForOtherWorkspaces={
                props.isUploadingForOtherWorkspaces
              }
              onCleanupUploadQueue={props.onCleanupUploadQueue}
              onDeleteExtractionsOrResults={props.onDeleteExtractionsOrResults}
              onDeleteAllExtractions={props.onDeleteAllExtractions}
              processingCount={props.processingCount}
              isUploadWidgetCollapsed={props.isUploadWidgetCollapsed}
              toggleIsUploadWidgetCollapsed={
                props.toggleIsUploadWidgetCollapsed
              }
              onRetry={props.onRetry}
              onRetryAllFailed={props.onRetryAllFailed}
              onReExtractExtraction={props.onReExtractExtraction}
              onReExtractAllFailedExtractions={
                props.onReExtractAllFailedExtractions
              }
              shouldShowEmptyPlaceholder={props.shouldShowEmptyPlaceholder}
            />
          </WorkspaceNavBarLayout>
        )}
        <LoadingModal isOpen={props.isLoading} />
        <LoadingModal
          messageId="workspace.document.deleting"
          isOpen={props.isDeleting}
        />
      </Main>
    </Layout>
  );
}
