import { IDropdownOption } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import { useConfirmModalActionCreator } from "../actions/confirmModal";
import { useWorkspaceActionCreator } from "../actions/workspace";
import { Layout, Main, Top } from "../components/Layout";
import { LoadingModal } from "../components/LoadingModal";
import { WorkspaceListSection } from "../components/WorkspaceList";
import { RESTRICTED_PREBUILT_EXTRACTOR_FLAGS } from "../constants";
import { WORKSPACE_PAGE_SIZE } from "../constants/layout";
import { PrebuiltExtractors } from "../constants/prebuiltExtractor";
import { useNotifyError } from "../hooks/error";
import { useAppSelector } from "../hooks/redux";
import { URLParamsKey, useSearchParamUtils } from "../hooks/searchParamUtils";
import { useToast } from "../hooks/toast";
import { ConfirmModalType } from "../types/confirmation";
import { extractorTypeOptions } from "../types/extractor";
import { Workspace } from "../types/workspace";
import { HeaderContainer } from "./Header";

export enum WorkspaceListType {
  Loading = "Loading",
  NoResultFound = "NoResultFound",
  ShowCreateWorkspace = "ShowCreateWorkspace",
  ShowWorkspaceGrid = "ShowWorkspaceGrid",
}

function getWorkspaceListType({
  isGettingWorkspace,
  totalCount,
  filter,
}: {
  isGettingWorkspace: boolean;
  totalCount: number;
  filter: string[];
}) {
  if (isGettingWorkspace) {
    return WorkspaceListType.Loading;
  }

  if (totalCount === 0) {
    if (filter.length > 0) {
      return WorkspaceListType.NoResultFound;
    }

    return WorkspaceListType.ShowCreateWorkspace;
  }
  return WorkspaceListType.ShowWorkspaceGrid;
}

function splitFilter(filter: string | null) {
  return filter ? filter.split(",") : [];
}

export function useFetchWorkspaces() {
  const { listWorkspaces } = useWorkspaceActionCreator();

  const resourceOwnerId = useAppSelector(
    state => state.resourceOwner.resourceOwnerId
  );
  const resourceOwnerIdRef = React.useRef(resourceOwnerId);
  resourceOwnerIdRef.current = resourceOwnerId;

  const lastListParams = useAppSelector(
    state => state.workspace.lastListParams
  );
  const lastListParamsRef = React.useRef(lastListParams);
  lastListParamsRef.current = lastListParams;

  const { setParam, setParams } = useSearchParamUtils();
  const [searchParam] = useSearchParams();

  const [listParams, setListParams] = React.useState<{
    resourceOwnerId: string | undefined;
    page: number;
    filter: string | null;
  } | null>(null);

  const {
    resourceOwnerId: resourceOwnerIdInUse,
    page: currentPage,
    filter: _filter,
  } = listParams ?? {
    page: 1,
    filter: null,
  };

  useNotifyError(state => state.workspace.workspaceError);

  const filter = React.useMemo(() => splitFilter(_filter), [_filter]);

  const navigateToPage = React.useCallback(
    (page: number) => {
      setParam(URLParamsKey.page, page.toString(), false);
    },
    [setParam]
  );

  const setFilter = React.useCallback(
    (filter: string[]) => {
      setParams(
        new Map([
          [URLParamsKey.page, "1"],
          [URLParamsKey.filterType, filter.join(",")],
        ]),
        false
      );
    },
    [setParams]
  );

  const refreshCurrentPage = React.useCallback(async () => {
    if (resourceOwnerIdInUse) {
      await listWorkspaces({
        resourceOwnerId: resourceOwnerIdInUse,
        page: currentPage,
        size: WORKSPACE_PAGE_SIZE,
        extractorTypesFilter: filter,
      });
    }
  }, [listWorkspaces, resourceOwnerIdInUse, currentPage, filter]);

  const isFirstAfterMountRef = React.useRef(true);
  const [isReadyToFetch, setIsReadyToFetch] = React.useState(false);
  React.useEffect(() => {
    if (isFirstAfterMountRef.current && lastListParamsRef.current) {
      const { page, filter } = lastListParamsRef.current;
      setParams(
        new Map([
          [URLParamsKey.page, page.toString()],
          [URLParamsKey.filterType, filter],
        ]),
        true
      );
    } else {
      setListParams({
        resourceOwnerId: resourceOwnerIdRef.current,
        page: parseInt(searchParam.get(URLParamsKey.page) ?? "1") ?? 1,
        filter: searchParam.get(URLParamsKey.filterType),
      });
      setIsReadyToFetch(true);
    }

    isFirstAfterMountRef.current = false;
  }, [setParams, searchParam]);

  React.useEffect(() => {
    if (isReadyToFetch && resourceOwnerIdInUse) {
      listWorkspaces({
        resourceOwnerId: resourceOwnerIdInUse,
        page: currentPage,
        size: WORKSPACE_PAGE_SIZE,
        extractorTypesFilter: filter,
      });
    }
  }, [
    isReadyToFetch,
    resourceOwnerIdInUse,
    currentPage,
    listWorkspaces,
    refreshCurrentPage,
    filter,
  ]);

  return React.useMemo(
    () => ({
      currentPage,
      refreshCurrentPage,
      navigateToPage,
      filter,
      setFilter,
    }),
    [currentPage, refreshCurrentPage, filter, setFilter, navigateToPage]
  );
}

export function useWorkspaceListContainer() {
  const toast = useToast();
  const navigate = useNavigate();
  const { deleteWorkspace } = useWorkspaceActionCreator();
  const { requestUserConfirmation } = useConfirmModalActionCreator();

  const [isLoading, setIsLoading] = React.useState(false);

  const {
    workspace: {
      workspaces,
      pageInfo: { totalCount },
    },
    isFeatureEnabled,
  } = useAppSelector(state => ({
    workspace: state.workspace.paginatedWorkspaceByPage[
      (state.workspace.lastListParams?.page || 1) - 1
    ] ?? {
      workspaces: [],
      pageInfo: {
        totalCount: 0,
      },
    },
    isFeatureEnabled: state.resourceOwner.isFeatureEnabled(),
  }));

  const isGettingWorkspace = useAppSelector(
    state => state.workspace.isListingWorkspaces
  );

  const { currentPage, navigateToPage, filter, setFilter, refreshCurrentPage } =
    useFetchWorkspaces();

  const availablePrebuiltExtractors = PrebuiltExtractors.filter(x => {
    const flag = RESTRICTED_PREBUILT_EXTRACTOR_FLAGS.get(x);
    return !flag || isFeatureEnabled(flag);
  });

  const onDropdownChange = React.useCallback(
    (
      _event: React.FormEvent<HTMLDivElement>,
      option?: IDropdownOption,
      _index?: number
    ): void => {
      if (!option) return;

      let newFilter: string[];

      if (option.key === "select_all") {
        newFilter = option.selected
          ? [...availablePrebuiltExtractors, ...extractorTypeOptions]
          : [];
      } else {
        newFilter = option.selected
          ? [...filter, option.key as string]
          : filter.filter(key => key !== option.key);
      }

      setFilter(newFilter);
    },
    [filter, setFilter, availablePrebuiltExtractors]
  );

  const onOpenWorkspace = React.useCallback(
    (workspace: Workspace) => {
      navigate(`/workspace/${workspace.id}`);
    },
    [navigate]
  );

  const onDeleteWorkspace = React.useCallback(
    async (workspace: Workspace) => {
      if (
        (await requestUserConfirmation(
          {
            titleId: "workspace.list.confirm_delete.title",
            messageId: "workspace.list.confirm_delete.message",
            actionId: "workspace.list.confirm_delete.action",
            type: ConfirmModalType.Normal,
          },
          false
        )) === false
      ) {
        return;
      }
      setIsLoading(true);
      try {
        await deleteWorkspace({ workspaceId: workspace.id });
        refreshCurrentPage();
      } catch {
        toast.error("error.workspace.fail_to_delete_workspace");
      } finally {
        setIsLoading(false);
      }
    },
    [deleteWorkspace, refreshCurrentPage, requestUserConfirmation, toast]
  );

  const workspaceListType = getWorkspaceListType({
    isGettingWorkspace,
    totalCount,
    filter,
  });

  return React.useMemo(
    () => ({
      workspaceListType,
      isGettingWorkspace,
      workspaces,
      currentPage,
      totalCount,
      onOpenWorkspace,
      onDeleteWorkspace,
      onNavigateToPage: navigateToPage,
      availablePrebuiltExtractors,
      onDropdownChange,
      selectedExtractorTypes: filter,
      isLoading,
    }),
    [
      workspaceListType,
      isGettingWorkspace,
      workspaces,
      currentPage,
      totalCount,
      onOpenWorkspace,
      onDeleteWorkspace,
      navigateToPage,
      availablePrebuiltExtractors,
      onDropdownChange,
      filter,
      isLoading,
    ]
  );
}

export default function WorkspaceListContainer() {
  const props = useWorkspaceListContainer();
  return (
    <Layout>
      <Top>
        <HeaderContainer />
      </Top>
      <Main hasTop={true}>
        <LoadingModal isOpen={props.isLoading} />
        <WorkspaceListSection {...props} />
      </Main>
    </Layout>
  );
}
